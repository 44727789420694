import React from 'react'
import CityPage from '../../components/cityPage'
import image from '../../images/cities/scale-model-of-college-station-texas.png'
import image0 from "../../images/cities/scale-model-of-sanders-corps-of-cadets-center-in-college-station-texas.png"
import image1 from "../../images/cities/scale-model-of-the-downtown-bryan-association-in-college-station-texas.png"
import image2 from "../../images/cities/scale-model-of-department-of-recreation,-park,-and-tourism-sciences-in-college-station-texas.png"

export default class LandingPage extends React.Component<{}, {}> {
  render() {
    return (
        <CityPage
            city='College Station'
            state='Texas'
            image={image}
            lat='30.627977'
            lon='-96.33440680000001'
            attractions={ [{"name": "Sanders Corps of Cadets Center", "vicinity": "Texas A&M University, 1400 Coke St, College Station", "types": ["museum", "point_of_interest", "establishment"], "lat": 30.612101, "lng": -96.33749599999999}, {"name": "The Downtown Bryan Association", "vicinity": "110 S Main St, Bryan", "types": ["point_of_interest", "establishment"], "lat": 30.673263, "lng": -96.37378269999999}, {"name": "Department of Recreation, Park, and Tourism Sciences", "vicinity": "Texas A&M University, Agriculture and Life Sciences, 600 John Kimbrough Blvd #415, College Station", "types": ["point_of_interest", "establishment"], "lat": 30.6056471, "lng": -96.3508089}] }
            attractionImages={ {"Sanders Corps of Cadets Center":image0,"The Downtown Bryan Association":image1,"Department of Recreation, Park, and Tourism Sciences":image2,} }
       />);
  }
}